import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import curriculumService from '../services/curriculumService';
import DatePicker from 'react-datepicker';
import { Grid, Color, Type, Border } from '../StyleGuide';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import { RadioListWithDescription, Radio } from '../components/FormElements';
import { sessionDateFilter } from '../filters/date-filters';
import { handleError } from '../utils/apiUtils';

const SelectSessionDate = styled.div`
  label {
    cursor: default;
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._1};
    font-weight: ${Type.Weight.bold};
    margin: ${Grid._4} 0 ${Grid._2};
  }
  input[type='text'] {
    font-size: ${Type.Scale._3};
    color: ${Type.Color.dark};
    border: 2px solid ${Color.Primary._50};
    border-radius: ${Border.radius};
    padding: ${Grid._3};
    :disabled {
      color: ${Type.Color.placeholder};
      background: ${Color.Gray._10};
      border-color: transparent;
    }
  }
  p {
    font-size: ${Type.Scale._1};
    margin-top: ${Grid._2};
  }
`;

const RescheduleSessionModal = ({
  orgId,
  curriculumId,
  initiatingUserId,
  session,
  handleDismiss,
  getSessions,
  firstAllowedDate,
}) => {
  const [isScheduled, setIsScheduled] = useState(session.isScheduled);
  const { scheduledDate, recommendedDate, availableStartDate, availableThroughDate } = sessionDateFilter(session);
  const firstAvailableDate = firstAllowedDate > availableStartDate ? firstAllowedDate : availableStartDate;
  const [date, setDate] = useState(firstAvailableDate > scheduledDate ? firstAvailableDate : scheduledDate);
  const [isScheduling, setIsScheduling] = useState(false);

  const setSchedule = () => {
    if ((isScheduled && scheduledDate.getTime() !== date.getTime()) || session.isScheduled !== isScheduled) {
      setIsScheduling(true);
      curriculumService
        .setSessionSchedule(
          orgId,
          curriculumId,
          session.sessionId,
          isScheduled ? date : scheduledDate,
          isScheduled,
          initiatingUserId
        )
        .then(getSessions)
        .catch(handleError)
        .finally(() => {
          setIsScheduling(false);
          handleDismiss();
        });
    } else {
      handleDismiss();
    }
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Reschedule Session</h3>
      </ModalHeader>
      <ModalBody preventScroll>
        <h3>{session.name}</h3>
        {firstAvailableDate && availableThroughDate && (
          <p>
            Available Dates: {format(firstAvailableDate, 'MMMM d')} - {format(availableThroughDate, 'MMMM d')}
          </p>
        )}
        <form>
          <RadioListWithDescription>
            <li>
              <label>
                <Radio
                  data-qa-hook="radioScheduled"
                  name="scheduled-date"
                  onChange={() => setIsScheduled(true)}
                  checked={isScheduled}
                />
                Schedule this session
              </label>
              <SelectSessionDate>
                <label htmlFor="selected-date">Scheduled Date</label>
                <DatePicker
                  data-qa-hook="datePicker"
                  selected={date}
                  onChange={date => setDate(date)}
                  minDate={firstAvailableDate}
                  maxDate={availableThroughDate}
                  showDisabledMonthNavigation
                  disabled={!isScheduled}
                />
                <p>Suggested Date: {format(recommendedDate, 'MMMM d')}</p>
              </SelectSessionDate>
            </li>
            <li>
              <label>
                <Radio
                  data-qa-hook="radioNotScheduled"
                  name="scheduled-date"
                  onChange={() => setIsScheduled(false)}
                  checked={!isScheduled}
                />
                Don't schedule this session
              </label>
              <p>
                Not scheduling this session will remove the date and show this session as "Not Scheduled" in the list of
                sessions wherever they are shown.
              </p>
            </li>
          </RadioListWithDescription>
        </form>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="rescheduleConfirm"
          onClick={() => setSchedule()}
          disabled={isScheduling}
          operating={isScheduling}
        >
          Reschedule
        </MediumPrimaryButton>
        <MediumButton onClick={() => handleDismiss()}>Cancel</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

RescheduleSessionModal.propTypes = {
  orgId: PropTypes.string.isRequired,
  curriculumId: PropTypes.string.isRequired,
  initiatingUserId: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  getSessions: PropTypes.func.isRequired,
  firstAllowedDate: PropTypes.instanceOf(Date),
};

export default RescheduleSessionModal;
