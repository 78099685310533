import React, { useState, useEffect } from 'react';
import Notifications from 'react-notify-toast';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Color, Breakpoints } from '../StyleGuide';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import {
  Label,
  FormGroupContainer,
  FormFieldContainer,
  FormFieldSet,
  Input,
  Radio,
  Select,
} from '../components/FormElements';
import { PrimaryButton, MediumOutlineButton } from '../components/Buttons';
import CourseInformationForm from './CourseInformationForm';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import Breadcrumbs from '../components/Breadcrumbs';
import licenseService from '../services/licenseService';
import trainingService from '../services/trainingService';
import windowService from '../services/windowService';
import orgService from '../services/orgService';
import alertService from '../services/AlertService';
import { ImageCropUtils } from '../components/ImageCrop';
import WhiteLabel from '../filters/WhiteLabel';
import SettingsHeader from '../components/SettingsHeader';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

const SelectContainer = styled.div`
  margin: ${Grid._5} 0;
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    margin: ${Grid._5} ${Grid._10};
  }
`;

const RadioList = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    padding: ${Grid._4};
    & + li {
      border-top: 1px solid ${Color.Blue._15};
    }
    label {
      flex: 1;
    }
    input[type='radio'] {
      margin-left: ${Grid._8};
    }
  }
`;

const showSuccess = category => alertService.show(`Course ${category} Saved`, 'success', 3000);
const showFailure = () =>
  alertService.show(
    'Oops, we’re unable to save changes at this time. We’re sorry for the inconvenience. Please try again later.',
    'error'
  );

export default function CourseSettings() {
  const user = useUser();
  const { trainingId } = useParams();
  const { manageTrainingRoutePrefix, buildType } = useManageTrainingRoute();

  const [data, setData] = useState({ course: { id: '' }, isLoading: true });
  const [formPreferences, setFormPreferences] = useState({
    hasSuggestedTimeFrame: false,
    suggestedTimeFrameDays: 5,
    showStepDiscussion: false,
    deliverCertificate: false,
    itemNumber: undefined,
  });
  const [categories] = useState(trainingService.getCategories());
  const isGridAuthor = !!user.author.find(c => c.shortCode === 'MG');
  const isSiteAuthor = !!user.author.find(c => c.shortCode === WhiteLabel.catalog.shortCode);
  const [ownerName, setOwnerName] = useState('');
  const [productNumbers, setProductNumbers] = useState();
  const [otherItemNumber, setOtherItemNumber] = useState('');
  const hideBreadcrumbNavigation = windowService.getParameterByName('origin');

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Courses', route: `#${manageTrainingRoutePrefix}/courses` },
    ...(data.course?.name
      ? [{ name: data.course.name, route: `#${manageTrainingRoutePrefix}/courses/${trainingId}` }, { name: 'Settings' }]
      : []),
  ];

  const isOtherProductNumber = (itemNumber, productNumbers) =>
    !!itemNumber && itemNumber !== productNumbers.paid && itemNumber !== productNumbers.free;
  const isAuthoring = isSiteAuthor && buildType === 'author';

  const getProductNumbers = itemNumber => {
    licenseService.getDefaultProductNumbers().then(productNumbers => {
      setProductNumbers(productNumbers);
      setOtherItemNumber(isOtherProductNumber(itemNumber, productNumbers) ? itemNumber : '');
    });
  };

  useEffect(() => {
    trainingService
      .getTasklist(trainingId)
      .then(course => {
        const isCourseOwner = user.lastSelectedAccount === course.owner;
        const isAuthorCourseOwner = isAuthoring && !!user.author.find(c => c._id.id === course.owner);
        const isOwner = isCourseOwner || isAuthorCourseOwner;
        setData({
          course: {
            ...course,
            isCourseOwner: isOwner,
            posterImage: course.posterImage
              ? trainingService.getTrainingImageUrl(course.posterImage)
              : course.posterImage,
          },
          isLoading: false,
        });
        setFormPreferences({
          category: course.category,
          hasSuggestedTimeFrame: course.suggestedTimeFrameDays > -1,
          suggestedTimeFrameDays: course.suggestedTimeFrameDays > -1 ? course.suggestedTimeFrameDays : 5,
          showStepDiscussion: !course.discussionDisabled,
          deliverCertificate: course.deliverCertificate,
          itemNumber: course.itemNumber,
        });
        getProductNumbers(course.itemNumber);
        if (isCourseOwner) {
          orgService.getOrgOverview(course.owner).then(({ name }) => setOwnerName(name));
        } else if (isAuthorCourseOwner) {
          setOwnerName(WhiteLabel.name);
        }
      })
      .catch(() => setData(data => ({ ...data, isLoading: false, isError: true })));
  }, []);

  const saveInformation = (values, { setSubmitting }) => {
    let name = values.name.trim();
    let description = values.description ? values.description.trim() : '';
    let promises = [];
    if (name !== data.course.name) {
      promises.push(trainingService.updateName(data.course.id, name, data.course.owner, user.userId));
    }
    if (description !== data.course.description) {
      promises.push(trainingService.updateDescription(data.course.id, description, user.userId));
    }
    if (values.category !== data.course.category) {
      if (values.category === 'No Category') {
        promises.push(trainingService.removeCategory(data.course.id, data.course.category, user.userId));
      } else {
        promises.push(
          trainingService.updateCategory(data.course.id, values.category, data.course.category, user.userId)
        );
      }
    }
    if (values.posterImage !== data.course.posterImage) {
      if (!values.posterImage && !!data.course.posterImage) {
        promises.push(trainingService.removeCourseImage(data.course.id));
      } else if (ImageCropUtils.isCroppedImage(values.posterImage)) {
        promises.push(
          ImageCropUtils.convertCroppedImageToBlob(values.posterImage).then(blob =>
            trainingService.uploadCourseImage(data.course.id, blob, ImageCropUtils.generateFilename())
          )
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        setData(data => ({
          course: { ...data.course, name: name, description: description, category: values.category },
        }));
        showSuccess('Information');
      })
      .catch(err => {
        console.error(err);
        err && err.eventType === 'TaskListNameDuplicateError'
          ? alertService.show('Sorry, that name is already taken', 'error', 3000)
          : showFailure();
      })
      .finally(() => setSubmitting(false));
  };

  const savePreferences = () => {
    setData(data => ({ ...data, isSubmitted: true }));
    if (formPreferences.itemNumber !== 'other' || (formPreferences.itemNumber === 'other' && otherItemNumber.length)) {
      let promises = [];
      setData(data => ({ ...data, isSubmitting: true }));
      if (
        formPreferences.hasSuggestedTimeFrame &&
        formPreferences.suggestedTimeFrameDays !== data.course.suggestedTimeFrameDays
      ) {
        setData(data => ({
          course: { ...data.course, suggestedTimeFrameDays: formPreferences.suggestedTimeFrameDays },
        }));
        promises.push(
          trainingService.setSuggestedTimeFrame(data.course.id, formPreferences.suggestedTimeFrameDays, user.userId)
        );
      } else if (formPreferences.hasSuggestedTimeFrame !== data.course.suggestedTimeFrameDays > -1) {
        setData(data => ({
          course: {
            ...data.course,
            hasSuggestedTimeFrame: formPreferences.hasSuggestedTimeFrame,
            suggestedTimeFrameDays: formPreferences.hasSuggestedTimeFrame
              ? formPreferences.suggestedTimeFrameDays
              : undefined,
          },
        }));
        if (formPreferences.hasSuggestedTimeFrame) {
          promises.push(
            trainingService.setSuggestedTimeFrame(data.course.id, formPreferences.suggestedTimeFrameDays, user.userId)
          );
        } else {
          promises.push(trainingService.unSetSuggestedTimeFrame(data.course.id, user.userId));
        }
      }
      if (formPreferences.showStepDiscussion !== !data.course.discussionDisabled) {
        setData(data => ({ course: { ...data.course, discussionDisabled: !formPreferences.showStepDiscussion } }));
        formPreferences.showStepDiscussion
          ? promises.push(trainingService.enableDiscussion(data.course.id, user.userId))
          : promises.push(trainingService.disableDiscussion(data.course.id, user.userId));
      }
      if (formPreferences.deliverCertificate !== data.course.deliverCertificate) {
        setData(data => ({ course: { ...data.course, deliverCertificate: formPreferences.deliverCertificate } }));
        formPreferences.deliverCertificate
          ? promises.push(trainingService.setCertificate(data.course.id, user.userId))
          : promises.push(trainingService.unSetCertificate(data.course.id, user.userId));
      }
      if (
        formPreferences.itemNumber !== data.course.itemNumber ||
        (isOtherProductNumber(formPreferences.itemNumber, productNumbers) && otherItemNumber !== data.course.itemNumber)
      ) {
        setData(data => ({
          course: {
            ...data.course,
            itemNumber: isOtherProductNumber(formPreferences.itemNumber, productNumbers)
              ? otherItemNumber
              : formPreferences.itemNumber,
          },
        }));
        if (!formPreferences.itemNumber) {
          promises.push(trainingService.removeItemNumber(data.course.id, user.userId));
        } else {
          if (isOtherProductNumber(formPreferences.itemNumber, productNumbers))
            setFormPreferences(formPreferences => ({ ...formPreferences, itemNumber: otherItemNumber }));
          isOtherProductNumber(formPreferences.itemNumber, productNumbers)
            ? promises.push(trainingService.setItemNumber(data.course.id, otherItemNumber, user.userId))
            : promises.push(trainingService.setItemNumber(data.course.id, formPreferences.itemNumber, user.userId));
        }
      }
      Promise.all(promises)
        .then(() => showSuccess('Preferences'))
        .catch(err => {
          console.error(err);
          showFailure();
        })
        .finally(() => setData(data => ({ ...data, isSubmitting: false, isSubmitted: false })));
    }
  };

  return (
    <>
      <Notifications />
      {!hideBreadcrumbNavigation && <Breadcrumbs crumbs={crumbs} />}
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container data-qa-hook="taskListSettingsView" style={{ margin: `${Grid._6} auto` }}>
          <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-40" style={{ alignItems: 'center' }}>
            <div className="grid-col-span-4">
              <SettingsHeader
                title={data.course.name}
                subtitle="Course Settings"
                icon={<i className="fas fa-cogs" />}
              />
            </div>
            <div className="grid-col-span-4">
              {data.course.isCourseOwner ? (
                <HelpAndInfoBox
                  title={`This is ${ownerName}'s Course`}
                  description="Changes made to this course will affect future assignments in all invitations and pathways."
                />
              ) : (
                <ErrorMessage>You do not own this course</ErrorMessage>
              )}
            </div>
          </div>
          <div
            className={
              'grid-container grid-col-4 grid-sm-col-8 grid-gap-40' +
              (!data.course.isCourseOwner && !isAuthoring ? ' disabled-ui' : '')
            }
          >
            <div className="grid-col-span-4">
              {categories && (
                <CourseInformationForm
                  course={data.course}
                  categories={categories}
                  showSuccess={showSuccess}
                  saveInformation={saveInformation}
                />
              )}
            </div>
            <div className="grid-col-span-4">
              <form>
                <FormFieldSet name={'Course Preferences'}>
                  <FormFieldContainer>
                    <Label>Discussion</Label>
                    <FormGroupContainer>
                      <p>Show the discussion tab when trainees are taking this course.</p>
                      <div className="controls">
                        <Radio
                          id="discussion-yes"
                          name="step-discussion"
                          onChange={() =>
                            setFormPreferences(formPreferences => ({ ...formPreferences, showStepDiscussion: true }))
                          }
                          checked={formPreferences.showStepDiscussion}
                        />
                        <label htmlFor="discussion-yes">Yes</label>
                        <Radio
                          id="discussion-no"
                          name="step-discussion"
                          onChange={() =>
                            setFormPreferences(formPreferences => ({ ...formPreferences, showStepDiscussion: false }))
                          }
                          checked={!formPreferences.showStepDiscussion}
                        />
                        <label htmlFor="discussion-no">No</label>
                      </div>
                    </FormGroupContainer>
                  </FormFieldContainer>
                  {user.isAuthor && (
                    <FormFieldContainer>
                      <Label>Course Certificate</Label>
                      <FormGroupContainer>
                        <p>Deliver a certificate after completing this course.</p>
                        <div className="controls">
                          <Radio
                            data-qa-hook="deliverCertificateRadio"
                            id="certificate-yes"
                            name="deliver-certificate"
                            onChange={() =>
                              setFormPreferences(formPreferences => ({
                                ...formPreferences,
                                deliverCertificate: true,
                              }))
                            }
                            checked={formPreferences.deliverCertificate}
                          />
                          <label htmlFor="certificate-yes">Yes</label>
                          <Radio
                            data-qa-hook="noCertificateRadio"
                            id="certificate-no"
                            name="deliver-certificate"
                            onChange={() =>
                              setFormPreferences(formPreferences => ({
                                ...formPreferences,
                                deliverCertificate: false,
                              }))
                            }
                            checked={!formPreferences.deliverCertificate}
                          />
                          <label htmlFor="certificate-no">No</label>
                        </div>
                      </FormGroupContainer>
                    </FormFieldContainer>
                  )}
                  <FormFieldContainer>
                    <Label>Suggested Time Frame</Label>
                    <FormGroupContainer>
                      <p>When this course is assigned, suggest a due date.</p>
                      <MediumOutlineButton
                        data-qa-hook="taskListSettingsTimeFrame"
                        onClick={() =>
                          setFormPreferences(formPreferences => ({
                            ...formPreferences,
                            hasSuggestedTimeFrame: !formPreferences.hasSuggestedTimeFrame,
                          }))
                        }
                      >
                        <i
                          className={
                            formPreferences.hasSuggestedTimeFrame ? 'fas fa-circle color-complete' : 'far fa-circle'
                          }
                        ></i>{' '}
                        Suggestion {formPreferences.hasSuggestedTimeFrame ? 'On' : 'Off'}
                      </MediumOutlineButton>
                    </FormGroupContainer>
                    <SelectContainer>
                      <Select
                        data-qa-hook="timeFrameDropdown"
                        value={formPreferences.suggestedTimeFrameDays}
                        onChange={e => {
                          let value = e.target.value;
                          setFormPreferences(formPreferences => ({
                            ...formPreferences,
                            suggestedTimeFrameDays: value,
                          }));
                        }}
                        disabled={!formPreferences.hasSuggestedTimeFrame}
                      >
                        <option value="0">Due Same Day</option>
                        <option value="1">Due in 1 Day</option>
                        <option value="2">Due in 2 Days</option>
                        <option value="3">Due in 3 Days</option>
                        <option value="4">Due in 4 Days</option>
                        <option value="5">Due in 5 Days</option>
                        <option value="6">Due in 6 Days</option>
                        <option value="7">Due in 1 Week</option>
                        <option value="14">Due in 2 Weeks</option>
                        <option value="21">Due in 3 Weeks</option>
                        <option value="28">Due in 4 Weeks</option>
                        <option value="35">Due in 5 Weeks</option>
                        <option value="42">Due in 6 Weeks</option>
                      </Select>
                    </SelectContainer>
                  </FormFieldContainer>
                  {isGridAuthor && productNumbers && (
                    <FormFieldContainer>
                      <Label>Course Access</Label>
                      <RadioList>
                        <p>Choose the product number that will give access to this course.</p>
                        <li>
                          <label data-qa-hook="paidProductNumberLabel" htmlFor="paid">
                            Paid: {productNumbers.paid}
                          </label>
                          <Radio
                            data-qa-hook="paidProductNumberRadio"
                            name="course-access"
                            onChange={() =>
                              setFormPreferences(formPreferences => ({
                                ...formPreferences,
                                itemNumber: productNumbers.paid,
                              }))
                            }
                            checked={formPreferences.itemNumber === productNumbers.paid}
                          />
                        </li>
                        <li>
                          <label data-qa-hook="freeProductNumberLabel" htmlFor="free">
                            Free: {productNumbers.free}
                          </label>
                          <Radio
                            data-qa-hook="freeProductNumberRadio"
                            name="course-access"
                            onChange={() =>
                              setFormPreferences(formPreferences => ({
                                ...formPreferences,
                                itemNumber: productNumbers.free,
                              }))
                            }
                            checked={formPreferences.itemNumber === productNumbers.free}
                          />
                        </li>
                        <li>
                          <label data-qa-hook="noProductNumberLabel" htmlFor="none">
                            Free (Full Preview): None
                          </label>
                          <Radio
                            data-qa-hook="noProductNumberRadio"
                            name="course-access"
                            onChange={() =>
                              setFormPreferences(formPreferences => ({ ...formPreferences, itemNumber: undefined }))
                            }
                            checked={!formPreferences.itemNumber}
                          />
                        </li>
                        <li>
                          <label htmlFor="other">
                            <Input
                              data-qa-hook="otherProductNumberInputField"
                              type="text"
                              value={otherItemNumber}
                              placeholder="Other"
                              disabled={!isOtherProductNumber(formPreferences.itemNumber, productNumbers)}
                              onFocus={() =>
                                setFormPreferences(formPreferences => ({ ...formPreferences, itemNumber: 'other' }))
                              }
                              onChange={e => {
                                setOtherItemNumber(e.target.value);
                              }}
                            />
                            {data.isSubmitted && !otherItemNumber.length && (
                              <ErrorMessage>Item number is required</ErrorMessage>
                            )}
                          </label>
                          <Radio
                            data-qa-hook="otherProductNumberRadio"
                            name="course-access"
                            onChange={() =>
                              setFormPreferences(formPreferences => ({ ...formPreferences, itemNumber: 'other' }))
                            }
                            checked={isOtherProductNumber(formPreferences.itemNumber, productNumbers)}
                          />
                        </li>
                      </RadioList>
                    </FormFieldContainer>
                  )}
                  <FormFieldContainer>
                    <PrimaryButton
                      data-qa-hook="taskListSavePreferences"
                      onClick={() => savePreferences()}
                      disabled={data.isSubmitting}
                      operating={data.isSubmitting}
                    >
                      Save Preferences
                    </PrimaryButton>
                  </FormFieldContainer>
                </FormFieldSet>
              </form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
