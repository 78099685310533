import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Type } from '../StyleGuide';
import { RoundedPrimaryBadge } from '../components/Badge';
import windowService from '../services/windowService';
import useCurriculumOnboardingDatastore from '../hooks/useCurriculumOnboardingDatastore';
import { useUser } from '../authentication';
import useCurriculum from '../hooks/useCurriculum';

const AgeGroups = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const AgeGroup = styled.li`
  margin-bottom: ${Grid._4};
`;

const AgeGroupSectionName = styled.p`
  font-size: ${Type.Scale._1};
  font-weight: ${Type.Weight.semibold};
  color: ${props => (props.theme === 'light' ? 'white' : Type.Color.dark)};
  margin-bottom: ${Grid._3};
`;

const ActionRowButton = styled.a`
  height: 57px;
  text-decoration: none;
  cursor: pointer;
  border-radius: ${Border.radius};
  color: ${Type.Color.dark} !important;
  padding: ${Grid._4};
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.medium};
  background: rgba(255, 255, 255, 0.7);
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.125s linear;
  i {
    color: ${Type.Color.dark};
  }
  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

const InlineBadge = styled.span`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._05};
  text-transform: uppercase;
  border: 1px solid ${Type.Color.dark};
  border-radius: 25px;
  padding: ${Grid._2} ${Grid._3};
`;

const InverseInlineBadge = styled(InlineBadge)`
  color: ${Type.Color.white};
  background: ${props => props.color || Type.Color.dark};
  border: none;
`;

const getItemNumberFromCurriculum = curriculum => {
  if (curriculum.trialItemNumber) return curriculum.trialItemNumber;
  return curriculum.license?.length > 0 ? curriculum.license[0].itemNumber : undefined;
};

export default function BibleStudyAgeGroups({ brandCode, ages, subscribedCurriculumIds, recentlyPairedCurriculumIds }) {
  const user = useUser();
  const { brand } = useCurriculum(brandCode);
  const { updateDatastore, deleteDatastore } = useCurriculumOnboardingDatastore(brandCode);
  const subscribedAgeGroups = ages.filter(age => subscribedCurriculumIds.includes(age.id));
  const unsubscribedAgeGroups = ages.filter(age => !subscribedCurriculumIds.includes(age.id));
  const isCruAsiaUser = user?.businessPartnerSources?.includes('CruAsia');

  const onSubscribeClick = (brandCode, ageId) => {
    if (isCruAsiaUser) return windowService.openUrlInSameTab('http://ministrygrid.com/cruasia');
    return windowService.redirectTo(`#/subscribe-bible-study/${brandCode}/${ageId}`);
  };

  const onPreviewClick = curriculum => {
    const { name } = curriculum;
    const itemNumber = getItemNumberFromCurriculum(curriculum);

    if (itemNumber) {
      updateDatastore({ items: [{ itemNumber, name }] });
    } else {
      deleteDatastore();
    }
    windowService.redirectTo(`#/curriculum/onboarding/${brandCode}`);
  };

  return (
    <>
      {!!subscribedAgeGroups.length && (
        <>
          <AgeGroupSectionName theme={brand.theme}>Subscribed Age Groups</AgeGroupSectionName>
          <AgeGroups>
            {subscribedAgeGroups.map((age, i) => {
              return (
                <AgeGroup data-qa-hook="ageGroupSubscribed" key={i}>
                  <ActionRowButton
                    data-qa-hook={`${brandCode}-${age.name}`}
                    href={`#/manage-bible-study/people/${brandCode}/${age.name}/${age.id}`}
                  >
                    {age.name}
                    {recentlyPairedCurriculumIds.includes(age.id) ? (
                      <RoundedPrimaryBadge>New</RoundedPrimaryBadge>
                    ) : (
                      <i className="fas fa-chevron-right" />
                    )}
                  </ActionRowButton>
                </AgeGroup>
              );
            })}
          </AgeGroups>
        </>
      )}
      {!!subscribedAgeGroups.length && unsubscribedAgeGroups.length > 0 && (
        <AgeGroupSectionName theme={brand.theme}>More Age Groups</AgeGroupSectionName>
      )}
      <AgeGroups style={{ marginTop: `${!subscribedAgeGroups.length ? '25px' : '0'}` }}>
        {unsubscribedAgeGroups.map(age => (
          <AgeGroup data-qa-hook="ageGroupUnsubscribed" key={age.id}>
            {age.previewable ? (
              <ActionRowButton data-qa-hook={`${brandCode}-${age.name}`} onClick={() => onPreviewClick(age)}>
                {age.name}
                <InverseInlineBadge color={brand.previewOptions?.accentColor}>Preview</InverseInlineBadge>
              </ActionRowButton>
            ) : (
              <ActionRowButton
                data-qa-hook={`${brandCode}-${age.name}`}
                onClick={() => onSubscribeClick(brandCode, age.id)}
              >
                {age.name}
                <InlineBadge>Subscribe</InlineBadge>
              </ActionRowButton>
            )}
          </AgeGroup>
        ))}
      </AgeGroups>
    </>
  );
}

BibleStudyAgeGroups.propTypes = {
  brandCode: PropTypes.string.isRequired,
  ages: PropTypes.array.isRequired,
  subscribedCurriculumIds: PropTypes.array,
  recentlyPairedCurriculumIds: PropTypes.array,
};
